import _ from 'lodash'

/**
 * TODO: Add better types some other time
 *
 * @typedef {Object} ChartColorSettings
 * @property {string[]} colors
 * @property {string[]} highlightColors
 *
 * @param {Record<string, unknown>} totalSegmentDataToCheck
 * @param {ChartColorSettings} chartColorSettings
 * @param {'options' | 'average.options' | 'answers'} blobDataKey
 */
export const assignOptionColors = (totalSegmentData, chartColorSettings, blobDataKey) => {
	const defaultColors = chartColorSettings.closeEnded.colors

	const options = _.get(totalSegmentData, blobDataKey)

	if (options === undefined) {
		throw new Error(`TotalSegmentData "${blobDataKey}" field is undefined`)
	}

	return {
		...totalSegmentData,
		options: options.map((option, index) => ({
			...option,
			color: defaultColors[index % defaultColors.length],
		})),
	}
}
